import { Text } from '@morressier/ts';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Auth = () => {
  const router = useRouter();

  useEffect(() => {
    if (!router.asPath.includes('#'))
      location.href = `${router.pathname}#account-signup?`;
  }, []);

  return (
    <Container>
      <Text size="h4_new" fontWeight="semiBold">
        Loading...
      </Text>
    </Container>
  );
};

export default Auth;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
